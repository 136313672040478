import * as I from './Input.styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string,
    placeholder?: string,
    type?: string
}

export const Input = ({label, type, placeholder, ...rest}: InputProps) => {
    return (
        <I.InputWrapper>
            <label htmlFor={label}>{label}</label>
            <input type={type} placeholder={placeholder} {...rest} />
        </I.InputWrapper>
    )
}