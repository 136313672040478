import styled from "styled-components";


export const PageContent = styled.div`
    padding: 80px;
    h1 {
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
    }
    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
`

export const RegisterWrapper = styled.div`
    display: flex;
    font-family: 'Inter';
    align-items: center;
    justify-content: center;
    gap: 88px;
`

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
`

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
`

export const PersonalInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 47px;
    margin-bottom: 75px;
`

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
`

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
`

export const Actions = styled.div`
    display: flex;
    gap: 16px;
`