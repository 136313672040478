import styled, { keyframes, css } from "styled-components";
import { transparentize } from 'polished';
import React, { ReactNode } from "react";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary';
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  pulse?: boolean;
  rightIcon?: ReactNode;
  loadingLabel?: string;
}

const THEMES: Record<string, any> = {
  primary: {
    background_color: '#1570EF',
    color: 'white',
    border: '1px solid #1570EF',
    hover: {
      background_color: transparentize(0.10, '#1570EF'),
    }
  },
  secondary: {
    background_color: 'white',
    color: 'black',
    border: '1px solid #D0D5DD',
    hover: {
      background_color: transparentize(0.10, '#D0D5DD'),
    }
  },
};

const ondasAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const animationPulse = css`
  animation: ${ondasAnimation} 1.5s infinite;
`;

export const CustomButton = styled.button<ButtonProps>`
  box-sizing: border-box;
  cursor: pointer;
  color: ${p => THEMES[p.variant].color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  font-weight: 600;
  width: 100%;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px !important;
  background-color: ${p => THEMES[p.variant].background_color};
  border: ${p => THEMES[p.variant].border};
  transition: all 0.2s ease-in-out;
  ${p => p.pulse ? animationPulse : null};

  &:hover {
    background-color: ${p => THEMES[p.variant].hover.background_color};
  }

  &:disabled {
    background-color: #D0D5DD;
    border-color: #D0D5DD;
    cursor: not-allowed;
  }
`;
