import jwt_decode from "jwt-decode";

export const getToken = () => localStorage.getItem('token')

export function getCnpj() {
    const token = localStorage.getItem('token')
    if (token) {
        const decoded: any = jwt_decode(token)
        return decoded.info_empresa.CNPJ
    }
    return ''
}

export function getTipoUser() {
  const token = getToken()
  if (token) {
      const decoded: any = jwt_decode(token)
      return decoded.tipo
  }
  return null
}


export function getUserId() {
  const token = localStorage.getItem('token')
  if (token) {
      const decoded: any = jwt_decode(token)
      return decoded.user_id
  }
  return ''
}

export function getRazaoSocialByToken(token: string) {
    const tk = token || localStorage.getItem('token')
    if (tk) {
        const decoded: any = jwt_decode(token)
        return decoded.info_empresa.razao_social
    }
    return ''
}


export function getRazaoSocial() {
  const token = localStorage.getItem('token')
  if (token) {
      const decoded: any = jwt_decode(token)
      return decoded.info_empresa.razao_social
  }
  return ''
}

export function validateToken() {
    try {
      const decodedToken: { exp: number } = jwt_decode(localStorage.getItem('token') || '');
      const currentTime = Date.now() / 1000;

      // Verifique se o token está expirado comparando a expiração com o tempo atual
      if (decodedToken.exp < currentTime) {
        return false;
      }

      return true;
    } catch (error) {
      // Erro ao decodificar o token
      return false;
    }
  }