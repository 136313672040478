import styled from "styled-components";


export const LoginWrapper = styled.div`
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: 'Inter', sans-serif;
`

export const LeftContainer = styled.div`
    flex: 1;
    background-color: #F9FAFB;
`

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;

    h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
}

h5 {
    margin-top: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/900 */

    color: #101828;
}

h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 10px;
    /* identical to box height, or 143% */

    text-align: center;

    /* Gray/600 */

    color: #475467;
}
`

export const StarsWrapper = styled.div`
    img {
        width: 20px;
        height: 20px;
    }
`

export const RightContainer = styled.div`
    flex: 1;
    background-color: white;
`

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;

        color: #101828;
    }

    span {
        color: #475467;
    }
`

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    a {
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #175CD3;
}
`