import styled from "styled-components";

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;

        
    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    input {

        box-sizing: border-box;

        /* Auto layout */

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 14px;
        gap: 8px;

        width: 100%;
        height: 44px;

        /* Base/White */

        background: #FFFFFF;
        /* Gray/300 */

        border: 1px solid #D0D5DD;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        /* Inside auto layout */

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        &:disabled {
            opacity: 0.5;
        }
    }

`