import axios from 'axios';
import { API_URL } from '../envs';
import { useNavigate } from 'react-router-dom';

const navigateToLogin = () => {
  window.location.href = '/';
};

const axiosWithAuth = axios.create({
  baseURL: API_URL,
});

axiosWithAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      navigateToLogin();
    }
    return Promise.reject(error);
  }
);

const axiosWithoutAuth = axios.create({
  baseURL: API_URL,
});

export { axiosWithAuth, axiosWithoutAuth };
