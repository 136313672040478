
import * as L from './Login.styles'
import Profile from '../../assets/svg/profile.svg'
import Star from '../../assets/svg/fullStar.svg'
import { Input } from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { API_URL } from '../../envs'
import { toast } from 'react-toastify';
import { getRazaoSocialByToken } from '../../services/token'
import { axiosWithoutAuth } from '../../services/axiosUtils'


export default function Login() {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const [form, setForm] = useState({
        username: '',
        password: '',
    });

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    useEffect(() => { 
        let token = localStorage.getItem('token');
        if(token) {
            localStorage.clear();
            window.location.reload();
        }
     }, []);

    const doLogin = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('username', form.username);
        formData.append('password', form.password);
        axiosWithoutAuth.post(`${API_URL}/login/`, formData).then(async (response: any) => {
            localStorage.setItem('token', response.data.tokens['access']);
            await new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
                const razao_social_split = getRazaoSocialByToken(response.data.tokens['access']).split(' ');
                toast.success(`Bem vindo! ${razao_social_split[0]} ${razao_social_split[1]}`);
                navigate('/home');
            })
              

        }).catch((err: any) => {
            toast.error('Usuário ou senha incorretos!');
            console.log(err);
        })
            .finally(() => setLoading(false));
    }

    return (
        <L.LoginWrapper>
            <L.LeftContainer>
                <L.LeftContent>
                    <h1>Nunca foi tão fácil conseguir um carro em perfeito estado para a minha cliente! Ela me agradece até hoje, pois não conseguia encontrar de jeito nenhum!</h1>
                    <img src={Profile} alt="" />
                    <h5>Antonia José</h5>
                    <h6>Revendedora Automotivo</h6>
                    <L.StarsWrapper>
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                        <img src={Star} alt="" />
                    </L.StarsWrapper>
                </L.LeftContent>
            </L.LeftContainer>
            <L.RightContainer>
                <L.RightContent>
                    <h1>Bem vindo novamente!</h1>
                    <p>Por favor informe os detalhes abaixo.</p>
                    <L.Form>
                        <Input label={'CNPJ'}
                            name="username"
                            value={form.username}
                            onChange={handleInput}
                            placeholder={'Insira seu cnpj'}
                            type={'cnpj'} />
                        <Input label={'Senha'}
                            name="password"
                            value={form.password}
                            onChange={handleInput}
                            placeholder={'Insira sua senha'}
                            type={'password'} />

                        <a>Esqueci a senha</a>

                        <div onClick={doLogin}>
                            <Button loading={isLoading} variant='primary' label={'Entrar'} />
                        </div>

                        <p>Ainda não é cadastrado? <a href="register">Cadastre-se</a></p>
                    </L.Form>
                </L.RightContent>
            </L.RightContainer>
        </L.LoginWrapper>
    )
}