import { styled } from "styled-components";
import { boolean } from "yargs";

export const StatusBarWrapper = styled.div<{active: boolean}>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    transition: .2s ease-in-out;

          
    h4 {
        margin: 0 !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${props => props.active ? '#1570EF' : '#eaecf0'};

    }

    h3 {
        margin: 0 !important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${props => props.active ? '#1570EF' : '#eaecf0'};
    }
`

export const StatusBar = styled.div<{active: boolean}>`
    width: 100%;
    height: 5px;
    transition: .2s ease-in-out;
    background-color: ${props => props.active ? '#1570EF' : '#eaecf0'};
    margin-top: 26px;
`