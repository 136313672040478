import * as B from './Button.styles'
import ReactLoading from 'react-loading';

export default function Button({variant, label, loading = false, disabled = false, pulse = false, loadingLabel, rightIcon, ...rest} : B.ButtonProps) {
    return (
        <B.CustomButton variant={variant} loading={loading} disabled={loading || disabled} {...rest} pulse={pulse}>
            {loading ?  <ReactLoading type="spin" color="white" height={16} width={16} /> : ''}
            {!loading ? <> {label} {rightIcon} </> : loadingLabel ? loadingLabel : <> {label} {rightIcon} </>}
            </B.CustomButton>
    )
}